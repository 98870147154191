@import "../../css/color-variables.css";

.chat {
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow: hidden;
}

.goBack {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    background-color: var(--white);
    color: var(--black);
}

.chatBackground {
    background-color: var(--white);
    width: 100%;
    overflow: scroll;
    height: calc(100vh - 93px);
}

.inputContainer {
    border-top: 1px solid var(--light-grey);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 12px;
    background-color: var(--white);
    position: fixed;
    bottom: 0;
    width: 100%;
}

.inputBox {
    padding: 0 5px;
    flex: 1;
}

.input {
    border: none;
    border-radius: 50px;
    background-color: var(--light-grey);
    height: 42px;
    width: 90%;
    font-size: 17px;
    padding-left: 15px;
}

.input:focus {
    border: 1px solid var(--purple);
}

.button {
    width: 36px;
    height: 36px;
    color: var(--white);
    background: linear-gradient(258.68deg, var(--purple) 2.36%, var(--light-purple) 100%);
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.messageContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    padding: 26px 26px 10px 10px;
}

.myMessageContainer {
    flex-direction: row-reverse;
    padding: 26px 10px 10px 26px;
}

.time {
    font-size: 11px;
    font-weight: 400;
    color: var(--grey);
    display: flex;
    justify-content: flex-start;
}

.message {
    padding-left: 10px;
    flex: 1;

    display: flex;
    justify-content: flex-end;
}

.myMessage {
    padding-left: 0;
    padding-right: 10px;
    justify-content: flex-start;
}

.messageText {
    background-color: var(--purple);
    padding: 15px;
    box-shadow: 0 9px 19px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    word-break: break-word;
    text-align: start;
    font-size: 17px;
    user-select: none;
}

.myMessageText {
    background-color: var(--light-grey);
    color: var(--black);
}

.messageBlock {
    position: relative;
}

.image {
    border: 1px solid var(--purple);
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.inputImage {
    width: 42px;
    height: 42px;
}

.messageUserInfo {
    justify-content: space-between;
    color: var(--black);
    font-size: 11px;
    font-weight: 600;
    display: flex;
    position: absolute;
    top: -16px;
    right: -16px;
}

.messageUserInfo > span {
    width: max-content;
}

.myMessageUserInfo {
    flex-direction: row-reverse;
    right: initial;
    left: -16px;
}
