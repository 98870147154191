:root {
    --light-grey: #F5F5F5;
    --grey: #B5B5B5;
    --grafit: #282c34;
    --white: #fff;
    --black: #000;
    --orange: #FFB951;
    --light-red: #F46262;
    --purple: #8762FF;
    --light-purple: #A762FF;
    --green: #50CC89;
}
