@import "../../css/color-variables.css";

.contextButton {
    border: none;
    padding: 15px;
    background-color: var(--grey);
    position: absolute;
    text-transform: capitalize;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

.delete  {
    color: var(--light-red);
}
