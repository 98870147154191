.App {
  text-align: center;
  overflow: hidden;
}

.App-container {
  background-color: var(--grafit);
  height: 100vh;
  display: flex;;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
  position: relative;
}

* {
  box-sizing: border-box;
}
