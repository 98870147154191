@import "../../css/color-variables.css";
@import "../../css/animation-variables.css";

.chatItem {
    width: 100%;
    display: flex;
    padding: 10px 0;
    background-color: var(--white);
}

.showButtons {
    animation: show-buttons var(--chat-buttons-time) linear;
}

.hideButtons {
    animation: hide-buttons var(--chat-buttons-time) linear;
}

.itemTab {
    display: flex;
    width: 100%;
    position: relative;
}

.buttons {
    display: flex;
}

.button {
    width: 76px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.8rem;
}

.button div > span {
    padding-top: 5px;
}

.block {
    background-color: var(--light-grey);
    color: var(--black);
}

.mute {
    background-color: var(--orange);
    color: var(--white);
}

.delete {
    background-color: var(--light-red);
    color: var(--white);
}

.chatItemTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chatItemInfoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: calc(100vw - 80px);
    padding-right: 8px;
}

.avatarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.avatar {
    border-radius: 50%;
    width: 56px;
    height: 56px;
    filter: drop-shadow(0px 4px 12px rgba(38, 0, 101, 0.1));
    object-fit: cover;
    margin-right: 15px;
    margin-left: 8px;
}

.online {
    background-color: var(--green);
    border: 2px solid var(--white);
    width: 12px;
    height: 12px;
    border-radius: 10px;
    position: absolute;
    bottom: 3px;
    right: 16px;
}

.name {
    font-size: 1rem;
    font-weight: 600;
    color: var(--black);
}

.date {
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--grey);
}

.text {
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--black);
    padding-right: 35px;
}

.count {
    font-size: 0.7rem;
    font-weight: 600;
    border-radius: 50px;
    background-color: var(--purple);
    padding: 5px 8px;
}

@keyframes show-buttons {
    0% {
        left: 0;
    }
    100% {
        left: -228px;
    }
}

@keyframes hide-buttons {
    0% {
        left: -228px;
    }
    100% {
        left: 0;
    }
}
